//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      showReply: !1,
      rows: 10,
      page: 1,
      total_number: 0,
      is_reply: -1,
      content: '',
      nickname: '',
      start_time: '',
      end_time: '',
      pay_sn: '',
      star_level: '',
      list: [],
      timeVal: '',
      reply: '', //回复内容
      reply_id: '',
      replyGoodsName: '',
      reply_list: [],
      replySelect: [
        {
          val: -1,
          label: '全部',
        },
        {
          val: 2,
          label: '是',
        },
        {
          val: 1,
          label: '否',
        },
      ],
      levelSelect: [
        {
          val: '',
          label: '全部',
        },
        {
          val: 1,
          label: '一级',
        },
        {
          val: 2,
          label: '二级',
        },
        {
          val: 3,
          label: '三级',
        },
        {
          val: 4,
          label: '四级',
        },
        {
          val: 5,
          label: '五级',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.content = '';
      this.nickname = '';
      this.pay_sn = '';
      this.star_level = '';
      this.is_reply = -1;
      this.timeVal = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    handelReply(index) {
      this.reply = '';
      this.reply_id = this.list[index].id;
      this.replyGoodsName = this.list[index].goods_title;
      this.showReply = !0;
    },
    getTime(val) {
      this.start_time = val[0].getTime() / 1000;
      this.end_time = val[1].getTime() / 1000;
    },
    showComment(index) {
      let status = this.list[index].status;
      this.$axios
        .post(this.$api.goods.setStatus, {
          id: this.list[index].id,
        })
        .then(res => {
          if (res.code == 0) {
            status == 1 ? (this.list[index].status = 2) : (this.list[index].status = 1);
            this.$message({
              message: '设置成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handelReplied(index) {
      this.reply_id = this.list[index].id;
      this.showReplied = !0;
    },
    confirmReply() {
      if (!this.reply)
        return this.$message({
          message: '请输入回复内容',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.goods.reply, {
          reply: this.reply,
          id: this.reply_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showReply = !1;
            this.getList();
            this.$message({
              message: '回复成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let data = {
        content: this.content,
        nickname: this.nickname,
        pay_sn: this.pay_sn,
        star_level: this.star_level,
        page: this.page,
        rows: this.rows,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      if (this.is_reply >= 1) data.is_reply = this.is_reply;
      this.$axios.post(this.$api.goods.commentList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.total_number = res.result.total_number;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
